import React from "react";
import {Link} from "react-router-dom";
import {loadImage} from "./elements/util/ImageLoder";


type props = {
    reload: () => void
}




export class Header extends React.Component<props, any> {
    nav = {
        home: "",
        about: "",
        production: "",
        cafe: "",
        franchising: "",
        partners: "",
        contacts: ""
    };

    constructor(a: any, b: any) {
        super(a, b);
        this.setNavBar();
        this.state = {
            lang_ua: "",
            lang_ru: "",
            lang_uk: "",
            logo: ""
        };
    }



    setNavBar() {
        if (localStorage.getItem("language") === "ua") {
            this.nav.home = "Головна";
            this.nav.about = "Про нас";
            this.nav.production = "Продукція";
            this.nav.cafe = "Кафе";
            this.nav.franchising = "Франчайзинг";
            this.nav.partners = "Партнери";
            this.nav.contacts = "Контакти";
        } else if (localStorage.getItem("language") === "ru") {
            this.nav.home = "Главная";
            this.nav.about = "О нас";
            this.nav.production = "Продукция";
            this.nav.cafe = "Кафе";
            this.nav.franchising = "Франчайзинг";
            this.nav.partners = "Партнеры";
            this.nav.contacts = "Контакты";
        } else if (localStorage.getItem("language") === "uk") {
            this.nav.home = "Home";
            this.nav.about = "About us";
            this.nav.production = "Production";
            this.nav.cafe = "Cafe";
            this.nav.franchising = "Franchising";
            this.nav.partners = "Partners";
            this.nav.contacts = "Contacts";
        }
    }

    setLangOnMount() {
        let tmp = {
            target: {
                id: localStorage.getItem("language")
            }
        };
        this.setActive("language_selection_link", tmp);
    }


    langController = (e: any) => {
        localStorage.setItem("language", e.target.id);
        this.props.reload();
    };

    tabController = (e: any) => {
        this.setActive("nav_link", e);
    };

    setActive(className: string, e: any) {
        let tabContent = document.getElementsByClassName(className);
        for (let i = 0; i < tabContent.length; i++) {
            tabContent[i].className = tabContent[i].className.replace(" active", "");
            if (tabContent[i].id === e.target.id) {
                tabContent[i].className += " active";
            }
        }
    }

    componentDidMount(): void {
        this.setLangOnMount();

        loadImage("ru.webp").then(res => {
            this.setState((state: any) => {
                let stateTmp = state;
                stateTmp.lang_ru = res.default;
                return {
                    stateTmp
                };
            });
        });

        loadImage("uk.webp").then(res => {
            this.setState((state: any) => {
                let stateTmp = state;
                stateTmp.lang_uk = res.default;
                return {
                    stateTmp
                };
            });
        });

        loadImage("logo.webp").then(res => {
            this.setState((state: any) => {
                let stateTmp = state;
                stateTmp.logo = res.default;
                return {
                    stateTmp
                };
            });
        });


        loadImage("ua.webp").then(res => {
            this.setState((state: any) => {
                let stateTmp = state;
                stateTmp.lang_ua = res.default;
                return {
                    stateTmp
                };
            });
        })

    }


    render() {
        return (
            <header className="header">
                <div className="container">
                    <div className="header_inner">
                        <a href="/">
                            <img className="header_logo" src={this.state.logo} alt=""/>
                        </a>
                        <nav className="nav">
                            <Link className="nav_link" to="/" onClick={this.tabController}
                                  id={"home"}>{this.nav.home}</Link>
                            <Link className="nav_link" to="about" onClick={this.tabController}
                                  id={"about"}>{this.nav.about}</Link>
                            <Link className="nav_link" to="products" onClick={this.tabController}
                                  id={"products"}>{this.nav.production}</Link>
                            <a className="nav_link" href="https://fr.lagelateriaitaliana.ua/" target="_blank"
                               rel="noopener noreferrer" onClick={this.tabController}>{this.nav.franchising}</a>
                            <Link className="nav_link" to="partners" onClick={this.tabController}
                                  id='partners'>{this.nav.partners}</Link>
                            <Link className="nav_link" to="contacts" onClick={this.tabController}
                                  id='contacts'>{this.nav.contacts}</Link>
                        </nav>
                        <div className="language_selection">
                            <img className="language_selection_link" id={"ua"} src={this.state.lang_ua}
                                 onClick={this.langController} alt=""/>
                            <img className="language_selection_link" id={"ru"} src={this.state.lang_ru}
                                 onClick={this.langController} alt=""/>
                            <img className="language_selection_link" id={"uk"} src={this.state.lang_uk}
                                 onClick={this.langController} alt=""/>
                        </div>
                    </div>

                </div>

            </header>
        )
    }
}
