import React from "react";
import "./SocialBar.css"
import facebook from "../../../resources/social/hollow-cut-facebook.svg"
/*import twitter from "../../../resources/social/hollow-cut-twitter.svg"*/
import youtube from "../../../resources/social/hollow-cut-youtube.svg"

export class SocialBar extends React.Component<any, any> {

    render() {
        return (
            <div className="sb">

                <a className="sb__link" href="https://www.facebook.com/gelatoua/" target="_blank"
                   rel="noopener noreferrer" aria-label={"facebook"}>
                    <img className="sb__image facebook" src={facebook} alt=""/>
                </a>

                {/*<a className="sb__link" href="https://twitter.com/lagelateriaital" target="_blank"
                   rel="noopener noreferrer">
                    <img className="sb__image twitter" src={twitter} alt=""/>
                </a>*/}

                <a className="sb__link" href="https://www.youtube.com/channel/UCbxbKWRTt0PP7atZZ2FYZYg" target="_blank"
                   rel="noopener noreferrer" aria-label={"youtube"}>
                    <img className="sb__image youtube" src={youtube} alt=""/>
                </a>

            </div>
        )
    }
}
