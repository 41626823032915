import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import React, {Suspense, lazy} from 'react';
import './App.css';
import {Header} from "./components/Header";
import {Footer} from "./components/Footer";

const Home = lazy(() => import('./components/pages/home/Home'));
const About = lazy(() => import('./components/pages/about/About'));
const Products = lazy(() => import('./components/pages/products/Products'));
const Partners = lazy(() => import('./components/pages/partners/Partners'));
const Contacts = lazy(() => import('./components/pages/contacts/Contacts'))


export class App extends React.Component<any, any> {


    constructor(props: Readonly<any>) {
        super(props);
        this.webpIsSupported();
        this.checkLangLocalStorage()
    }

    webpIsSupported() {

        if(!window.createImageBitmap){
            localStorage.setItem("webpSupp", "false");
            return;
        }

        let webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

        fetch(webpData).then(function (response) {
            return response.blob();
        }).then(function (blob) {
            createImageBitmap(blob).then(function () {
                localStorage.setItem("webpSupp", "true");
            }, function () {
                localStorage.setItem("webpSupp", "false");
            });
        });

    }

    checkLangLocalStorage() {
        let lang = localStorage.getItem("language");
        if (lang == null) {
            localStorage.setItem("language", "ua");
        }
    }

    reload = () => {
        window.location.reload();
    };


    render() {
        return (
            <div>
                <a className="skip-link" href="/">Skip to main</a>
                <Router>
                    <Header reload={this.reload}/>
                    <main>
                        <Suspense fallback={<div className="loading"/>}>
                            <Switch>
                                <Route exact path="/" component={Home}/>
                                <Route path="/about" component={About}/>
                                <Route path="/products" component={Products}/>
                                <Route path="/partners" component={Partners}/>
                                <Route path="/contacts" component={Contacts}/>
                            </Switch>
                        </Suspense>
                    </main>
                    <Footer/>
                </Router>

            </div>
        );
    }
}

export default App;
