import React from "react";
import "./Footer.css"
import {SocialBar} from "./elements/socialbar/SocialBar";
import {loadImage} from "./elements/util/ImageLoder";


let text =
    "\t+380-50-407-73-14\n" +
    "\t+380-66-282-45-73 (Viber)\n" +
    "E-mail: horeca@gelato.com.ua\n";
export class Footer extends React.Component<any, any>{


    constructor(props: Readonly<any>) {
        super(props);
        let contactString;
        let language = localStorage.getItem("language");
        if (language === "ua") {
            contactString = "Контакти"
        } else if (language === "ru") {
            contactString = "Контакты"
        } else if (language === "uk") {
            contactString = "Contacts"
        }
        
        text = contactString + ":\n" + text;

        this.state = {
            logo: ""
        };
    }

    componentDidMount(): void {
        loadImage("logo.webp").then(res => {
            this.setState((state: any) => {
                let stateTmp = state;
                stateTmp.logo = res.default;
                return {
                    stateTmp
                };
            });
        });
    }

    render(){
        return(
            <footer className="footer">
                <div className="footer__inner">
                    <SocialBar/>
                    <img className="footer__logo" src={this.state.logo} alt=""/>
                    <div className= "footer__contacts">{text}</div>
                </div>
            </footer>
        )
    }

}
