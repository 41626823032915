export async function loadImage(fileName: string) {


    if (localStorage.getItem("webpSupp") === "false"){
        let imgSplit = fileName.split(".");
        imgSplit[1] = "png";
        fileName = imgSplit[0] + "." + imgSplit[1];
    }
    return import("../../../resources/" + fileName);
}
